import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import TransportAirCharter from '../../components/TransportAirCharter';
//import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
import aircharter1 from '../../images/aircharter/aircharter1.jpg'
//import ocean1 from '../../images/ocean/ocean1.jpg'
import ocean2 from '../../images/ocean/ocean2.jpg'
//import air2 from '../../images/air/2.jpg'
import ScrollToTop from '../../components/scrollTop/scrolltop'

const AirCharter = () => {
    return(
       <div>
            
           <Navbar/>
           <Breadcumb bdtitle={'Air Charter'} bdsub={'Air Charter'}/>
           <TransportAirCharter simg={aircharter1} simg2={ocean2}/>
           <FooterSection/>
       </div>
    )
}

export default AirCharter;