import Carousel from "react-multi-carousel";
import partner1 from "../../images/partners/1.png";
import partner2 from "../../images/partners/2.png";
import partner3 from "../../images/partners/3.png";
import partner4 from "../../images/partners/4.png";
import partner5 from "../../images/partners/5.png";
import partner6 from "../../images/partners/6.png";
import partner7 from "../../images/partners/7.png";
import partner8 from "../../images/partners/8.png";
import partner9 from "../../images/partners/9.png";
import partner10 from "../../images/partners/10.png";
import partner11 from "../../images/partners/11.png";
import partner12 from "../../images/partners/12.png";
import partner13 from "../../images/partners/13.png";
import partner14 from "../../images/partners/14.png";
import partner15 from "../../images/partners/15.png";
import "react-multi-carousel/lib/styles.css";
import React from 'react';
import "./styles.css";

const PartnerSlider = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 7
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 1024, min: 300 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 300, min: 0 },
          items: 1
        }
      };
      
    return(
      <div>
        <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Ongoing Esteemed</span>
                        <h2>Clients</h2>
                    </div>
        </div>
        <Carousel responsive={responsive} autoPlay="true" infinite="true" autoPlaySpeed="3000">
        <div><img src={partner1} alt="Partner1"></img></div>
        <div><img src={partner2} alt="Partner2"></img></div>
        <div><img src={partner3} alt="Partner3"></img></div>
        <div><img src={partner4} alt="Partner4"></img></div>
        <div><img src={partner5} alt="Partner5"></img></div>
        <div><img src={partner6} alt="Partner6"></img></div>
        <div><img src={partner7} alt="Partner7"></img></div>
        <div><img src={partner8} alt="Partner8"></img></div>
        <div><img src={partner9} alt="Partner9"></img></div>
        <div><img src={partner10} alt="Partner10"></img></div>
        <div><img src={partner11} alt="Partner11"></img></div>
        <div><img src={partner12} alt="Partner12"></img></div>
        <div><img src={partner13} alt="Partner13"></img></div>
        <div><img src={partner14} alt="Partner14"></img></div>
        <div><img src={partner15} alt="Partner15"></img></div>
      </Carousel>
      </div>
    )
}

export default PartnerSlider;