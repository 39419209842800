import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportRoRo = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: +971 4 3245 604</p>
                                        <p>Tel: +971 54 3611 44 1</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>nisarg@vedslogistics.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Blue Matrix Business Centre,Prime Tower, Business Bay,Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/AirCharter">AirCharter</Link></li>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                            <li><Link to="/BreakBulk">BreakBulk</Link></li>
                                            <li><Link to="/RoRo">RoRo</Link></li>
                                            <li><Link to="/Warehousing">WareHousing</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                            <img src={props.simg} alt=""/>
                            <div className="Freight-s">
                                <div className="f-s">
                                    <p>Warehousing is an important piece of your retail supply chain. While it’s not the best of subjects, warehousing and inventory storage affect everything from sourcing raw materials and, efficiently managing inventory, to getting orders delivered to customers on time.</p>
                                    <p>Though the principles of warehousing have not changed much over the years, warehousing solutions have evolved a lot.</p>
                                    <p>With new technologies, urbanization, and the ever-growing world of online shopping, warehousing has never been a hotter topic — so much so that there has even been a shortage of warehouse space and on-demand warehousing popping up.</p>
                                    <p>Defining 3 key parts of warehousing logistics: A few of the most important elements of warehousing include warehouse management, warehousing services, operations, and warehouse management systems.</p>
                                    <ol>
                                        <li>Warehouse management:</li>
                                        <p>Warehouse management is the strategic day-to-day running of operations in a warehouse to promote, improve, and ensure operational excellence. This includes overseeing all staff, training, inventory, equipment, safety and security, relationships with shipping carriers and so on.</p>
                                        <li>Warehouse operations:</li>
                                        <p>Objective of  warehousing operations is to maintain low costs, productive staff, storage optimization, equipment used effectively thus customer satisfaction.</p>
                                        <li>Warehouse management system:</li>
                                        <p>Warehouse management systems help optimize inventory storage and tracking, warehousing operations, workload distribution, and shipping.</p>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportRoRo;

/*
<div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Ocean Logistics & Transport</h3>
                            <p>Besides our 3 decades of exposure in the conservative forwarding industry, we take pride in having niche of moving Ocean / RFS combination movements & Break-bulk projects consisting Drybulk, Ship chartering, Yacht transport, RORO and even bulk containerized shipping.</p>
                            <img src={props.simg} alt=""/>
                            <p>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>Commodities that we have executed are - Infrastructure & construction heavy vehicles, Military equipment, Oilfield cargo, Transformers & generators, Dry bulk commodities like cement, grains, clinkers etc., Pre-fabricated housing, Armored & general cars, Luxury massive Yachts, small Coast guard vessels & helicopters for repair & return.</span>
                                    <span>RORO and vehicles of any kind, be it commercial or government/military use, armored or civilian, we can handle.</span>
                                </div>
                            </div>
                        </div>
                    </div>
*/