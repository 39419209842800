import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import TransportOcean from '../../components/TransportOcean'
//import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
import ocean1 from '../../images/ocean/ocean1.jpg'
import ocean2 from '../../images/ocean/ocean2.jpg'
//import air2 from '../../images/air/2.jpg'

const OceanFreight = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Ocean Freight'} bdsub={'Ocean Freight'}/>
           <TransportOcean simg={ocean1} simg2={ocean2}/>
           <FooterSection/>
       </div>
    )
}

export default OceanFreight;