import React from 'react';
import {Link} from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import in1 from '../../images/instragram/1.jpg'
import in2 from '../../images/instragram/2.jpg'
import in3 from '../../images/instragram/3.jpg'
import in4 from '../../images/instragram/4.jpg'
import in5 from '../../images/instragram/5.jpg'
import in6 from '../../images/instragram/6.jpg'
import './style.css'

const FooterSection = () => {

    return(
        <div className="wpo-footer-area">
            <div className="wpo-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-sm-6 footer-t">
                            <div className="wpo-footer-logo">
                                <img src={logo} alt=""/>
                            </div>
                            <p>Veds’ Logistics Solutions (VEDS) is an end to end specialized logistic services provider with a dedicated and aggressive partners network in most of the globe.</p>
                            <p>
                            <ul>
                                <li>Trade License No. 602220 with Dubai Chamber</li>
                                <li>VAT TRN: 100233089000003</li>
                            </ul>
                            </p>
                            <div className="social">
                                <ul className="d-flex">
                                    <li><a href="https://www.facebook.com/VEDS-Logistic-Solutions-LLC-101554562066442"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="https://instagram.com/vedslogistic?igshid=cy0m8e2fpk8u"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/veds-logistic-solutions-llc"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 footer-t">
                            
                        </div>
                        
                        <div className="col-lg-5 col-sm-6 col-12">
                            <div className="footer-widget instagram">
                                <h3>Instagram</h3>
                                <ul className="d-flex">
                                    <li><Link to="/gallery"><img src={in1} alt=""/></Link></li>
                                    <li><Link to="/gallery"><img src={in2} alt=""/></Link></li>
                                    <li><Link to="/gallery"><img src={in3} alt=""/></Link></li>
                                    <li><Link to="/gallery"><img src={in4} alt=""/></Link></li>
                                    <li><Link to="/gallery"><img src={in5} alt=""/></Link></li>
                                    <li><Link to="/gallery"><img src={in6} alt=""/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-footer-bottom">
                <div className="container">
                    <div className="wpo-footer-bottom-content">
                        <div className="row">
                            <div className="col-12">
                                <span>© Copyrights 2021 @ Veds' Logistic Solutions LLC. All Rights Reserved.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default FooterSection;

/*
<div className="footer-link">
                                <h3>Quick Link</h3>
                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/">Service</Link></li>
                                    <li><Link to="/">Location</Link></li>
                                    <li><Link to="/">Contact</Link></li>
                                </ul>
                            </div>

<div className="Recent-News-area">
                                <h3>Recent News</h3>
                                <div className="resent-sub">
                                    <p>There are many variations of passages of Lorem</p>
                                    <span><i className="fa fa-clock-o" aria-hidden="true"></i> Octobor 10, 2018</span>
                                </div>
                                <p>There are many variations of passages of Lorem</p>
                                <span><i className="fa fa-clock-o" aria-hidden="true"></i> Octobor 10, 2018</span>
                            </div>
*/