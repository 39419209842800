import React from 'react';
//import abimg2 from '../../images/about/2.png'

import './style.css'

const AboutSection2 = () => {

    return(
        <div className="wpo-about-style-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-6  offset-lg-6 about-wrap">
                    <div className="wpo-about-content">
                        <div className="wpo-about-icon">
                            <i className="fi flaticon-travel"></i>
                        </div>
                        <h2>Who We Are?</h2>
                        <p>Veds’ Logistic Solutions L.L.C. (VEDS) is an end to end specialized Logistic services provider with dedicated team of resourceful Partners’ network across the Globe.</p>
                        <p>By leveraging the capabilities and expertise of our accumulated global industry exposure over 30 years, we believe our contribution lies in improving the economic performance of its partners’ supply chain needs by executing almost impossible and challenging tasks & projects.</p>
                        <span>30+ years Industry experience</span>
                        <span>Delivering on Time, Always</span>
                        <span>Complete Transperency</span>
                    </div>
                    <div className="signature-section">
                        <div className="si-text">
                            <p>Ashok Ved</p>
                            <span>Founder & Managing Director</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
     )
        
}

export default AboutSection2;

/*
<img src={abimg2} alt=""/>
*/
