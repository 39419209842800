import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import TransportRoRo from '../../components/TransportRoRo';
//import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
import roro from '../../images/roro/roro.jpg'
//import ocean1 from '../../images/ocean/ocean1.jpg'
import ocean2 from '../../images/ocean/ocean2.jpg'
//import air2 from '../../images/air/2.jpg'

const RoRo = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'RoRo'} bdsub={'RoRo'}/>
           <TransportRoRo simg={roro} simg2={ocean2}/>
           <FooterSection/>
       </div>
    )
}

export default RoRo;