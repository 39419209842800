import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportAirCharter = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: +971 4 3245 604</p>
                                        <p>Tel: +971 54 3611 44 1</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>nisarg@vedslogistics.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Blue Matrix Business Centre,Prime Tower, Business Bay,Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/AirCharter">AirCharter</Link></li>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                            <li><Link to="/BreakBulk">BreakBulk</Link></li>
                                            <li><Link to="/RoRo">RoRo</Link></li>
                                            <li><Link to="/Warehousing">WareHousing</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Air Charter</h3>
                            <img src={props.simg} alt=""/>
                            <div className="Freight-s">
                                <div className="f-s">
                                    <p>Concentration is a Virtue in Logistics. We at VEDS’ look into smallest of aspect in Planning of handling any Air Charter execution.</p>
                                    <p>From time critical, outsize or high value cargo to commercial aircraft charter, VEDS’ is your foremost point of choice across the globe. We offer complex air cargo solutions for urgent deliveries, emergency freight and immediate availability of charters with proficient solutions. Our team of professionals are experts in their field. They’ll take the time to listen carefully to your needs and, armed with that information, will be able to source the cargo air charter that’s perfectly suited to your requirements.</p>
                                    <p>We take pride in sharing of having executed numerous flights with zero discrepancy (mostly AN124 IL76 aircrafts) in less than 8 years. Commodities we have handled are - transformers & generators, over-sized construction & infrastructure equipment, over-weight transport vehicles, pre-fabricated housing, AOG, Helicopters for repairs & return, armored cars, breakbulk cargo, temperature controlled items and more.</p>
                                    <p><b>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</b></p>
                                    <ul>
                                        <li><p>We work directly with aircraft owners and operators - eliminating any brokers offering more proficient solutions.</p></li>
                                        <li><p>Quickest turnaround time, our team is exclusive so you wont have to wait hours and hours to get a solution.</p></li>
                                        <li><p>We are physically present for all of our projects, overlooking the operations from documentation to loading and all of it. Your cargo is in safe hands.</p></li>
                                        <li><p>We value our customers and their goods, and treat it like our own property with utmost transparency and confidentiality.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportAirCharter;

/*
<div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Ocean Logistics & Transport</h3>
                            <p>Besides our 3 decades of exposure in the conservative forwarding industry, we take pride in having niche of moving Ocean / RFS combination movements & Break-bulk projects consisting Drybulk, Ship chartering, Yacht transport, RORO and even bulk containerized shipping.</p>
                            <img src={props.simg} alt=""/>
                            <p>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>Commodities that we have executed are - Infrastructure & construction heavy vehicles, Military equipment, Oilfield cargo, Transformers & generators, Dry bulk commodities like cement, grains, clinkers etc., Pre-fabricated housing, Armored & general cars, Luxury massive Yachts, small Coast guard vessels & helicopters for repair & return.</span>
                                    <span>RORO and vehicles of any kind, be it commercial or government/military use, armored or civilian, we can handle.</span>
                                </div>
                            </div>
                        </div>
                    </div>
*/