import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './style.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//import team_1 from '../../images/team/1.jpg';
import team_2 from '../../images/team/2.jpg';
import team_3 from '../../images/team/3.jpg';

class TeamSection extends Component {
    render() {
        return (
            <div className="wpo-team-area-2">
                <div className="container">
                    <div className="col-l2">
                        <div className="wpo-section-title text-center">
                            <span>We Are With You</span>
                            <h2>Our Team</h2>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={team_2} alt=""/>
                                    <div className="social-1st">
                                        <ul>   
                                        <li><a href="https://www.linkedin.com/in/ashok-ved-97905527/"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4><b>Ashok Ved</b></h4>
                                    <span>Founder and Managing Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-12">
                            <div className="wpo-team-single">
                
                                <div className="wpo-team-img">
                                
                                </div>
                                <div className="wpo-team-content">
                                    <p>Having spent most of his adulthood right from his birth in the Middle East, Ashok founded VEDS’ in 2007. With background of Shipping, Airline and conservative forwarding streams of Logistic industry with the likes of Inchcape Shipping, British Airways, APL Logistics and leading a Group company owned by a member of Royal family across Oman Kuwait & UAE of 30 years, in VEDS’, Ashok has developed niche of Air Charters and Project execution.</p>
                                    <p>Our true investment is trust in our partners turned into friends and our loyal customers whom we work with throughout from the very first day of association. A family oriented person, Ashok believes in constant transformation and evolution through learning process at all levels operating with complete transparency offering quality service.</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="col-l2">
                        
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                            <div className="wpo-team-img">
                                    <img src={team_3} alt=""/>
                                    <div className="social-1st">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/in/nisarg-ved"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4><b>Nisarg Ved</b></h4>
                                    <span>Partner and Business Development Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-12">
                            <div className="wpo-team-single">
                
                                <div className="wpo-team-img">
                                
                                </div>
                                <div className="wpo-team-content">
                                    <p>A Bachelor’s Graduate from Symbiosis International School of Business, Pune India, with a blend of Middle Eastern culture by birth, Nisarg is groomed into Family owned Logistic business as Partner with 3 decades of industry exposure as his back bone</p>
                                    <p>Willing to adapt as things evolve, this young entrepreneur with thriving enthusiasm of natural thought process, Nisarg is ready to take driving seat of VEDS’ into the next level. A strong believer in virtues, Nisarg wishes to add value to his Clients’ business for ensuring each transaction turning into mutually beneficial Partnership, for life.</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamSection;       




/*
<li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
*/

/*
<div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={team_1} alt=""/>
                                    <div className="social-1st">
                                        <ul>
                                            <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Elizabeth Bannet</h4>
                                    <span>Logistic Maneger</span>
                                </div>
                            </div>
                        </div>
*/