import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportRoRo = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: +971 4 3245 604</p>
                                        <p>Tel: +971 54 3611 44 1</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>nisarg@vedslogistics.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Blue Matrix Business Centre,Prime Tower, Business Bay,Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/AirCharter">AirCharter</Link></li>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                            <li><Link to="/BreakBulk">BreakBulk</Link></li>
                                            <li><Link to="/RoRo">RoRo</Link></li>
                                            <li><Link to="/Warehousing">WareHousing</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                        <h3>RoRo</h3>
                            <img src={props.simg} alt=""/>
                            <div className="Freight-s">
                                <div className="f-s">
                                    <p>RoRo allows your products to roll on and off the vessel, as opposed to being lifted onboard using cranes. Self-propelled products, such as cars and tractors, roll on and off the vessel on their own wheels. Products that are not self-propelled are placed on handling equipment with wheels in the terminal to be rolled on and off the vessel. Your cargo thus remains on the handling equipment for the entire sea voyage.</p>
                                    <p>RoRo can handle cargo upto maximum specifications:</p>
                                    <ul>
                                        <li>Up to 6.1 metres high</li>
                                        <li>Up to 12 metres wide</li>
                                        <li>Up to 400 tonnes in weight.</li>
                                    </ul>
                                    <br></br>
                                    <p>Some shipping solutions involve multiple lifting operations and cargo is exposed to the elements on open deck. RoRo transport involves minimal lifting of your product as well as dry and safe storage inside the vessel, protected from the environment.</p>
                                    <p>The inside of a RoRo vessel is a flexible space that can accommodate many different types of cargo, with ramps linking decks. Once the right deck has been selected for the breakbulk in question, it’s safely secured and then not lifted again during ocean transit. That helps to prevent damage.</p>
                                    <p>RoRo is reliable and gives you supply chain predictability in the long run.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportRoRo;

/*
<div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Ocean Logistics & Transport</h3>
                            <p>Besides our 3 decades of exposure in the conservative forwarding industry, we take pride in having niche of moving Ocean / RFS combination movements & Break-bulk projects consisting Drybulk, Ship chartering, Yacht transport, RORO and even bulk containerized shipping.</p>
                            <img src={props.simg} alt=""/>
                            <p>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>Commodities that we have executed are - Infrastructure & construction heavy vehicles, Military equipment, Oilfield cargo, Transformers & generators, Dry bulk commodities like cement, grains, clinkers etc., Pre-fabricated housing, Armored & general cars, Luxury massive Yachts, small Coast guard vessels & helicopters for repair & return.</span>
                                    <span>RORO and vehicles of any kind, be it commercial or government/military use, armored or civilian, we can handle.</span>
                                </div>
                            </div>
                        </div>
                    </div>
*/