import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportAir = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: +971 4 3245 604</p>
                                        <p>Tel: +971 54 3611 44 1</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>nisarg@vedslogistics.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Blue Matrix Business Centre,Prime Tower, Business Bay,Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/AirCharter">AirCharter</Link></li>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                            <li><Link to="/BreakBulk">BreakBulk</Link></li>
                                            <li><Link to="/RoRo">RoRo</Link></li>
                                            <li><Link to="/Warehousing">WareHousing</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                        <h3>Air Freight</h3>
                            <img src={props.simg} alt=""/>
                            <div className="Freight-s">
                            
                                <div className="f-s">
                                    <p>Today’s competitive environment rewards a flexible and responsive approach to logistics. When you need reliable air freight to achieve your business goals, we can support you with our wide range of services.</p>
                                    <p>Fast moving, challenging and constantly changing – airfreight spurs us on to be creative and flexible as we seek to deliver the most appropriate services to you. Global capacity and demand can change in the blink of an eye and we are committed to providing stable, reliable services which maintain your supply chain’s integrity.</p>
                                    <p>Whether your shipments fly in the bellies of commercial aircraft or require specialist freighter aircraft to move them our team of air freight experts will deliver tailor-made solutions to solve your business challenges.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportAir;


/*
<div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

/*
<h3>Air Logistics & Transport</h3>

<div className="Freight-item">
                            <h3>Air Logistics & Transport</h3>
                            <p>Today’s competitive environment rewards a flexible and responsive approach to logistics. When you need reliable air freight to achieve your business goals, we can support you with our wide range of services.</p>
                            <img src={props.simg} alt=""/>
                            <p>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>We work directly with aircraft owners and operators - eliminating any middle brokers offering more proficient solutions.</span>
                                    <span>Quickest turnaround time, our team is exclusive so you wont have to wait hours and hours to get a solution.</span>
                                    <span>We are physically present for all of our projects, overlooking the operations from documentation to loading and all of it. Your cargo is in safe hands.</span>
                                    <span>We value our customers and their goods, and treat it like our own property with utmost transparency and confidentiality.</span>
                                </div>
                            </div>
                        </div>
*/