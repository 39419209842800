import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportOcean = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: +971 4 3245 604</p>
                                        <p>Tel: +971 54 3611 44 1</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>nisarg@vedslogistics.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Blue Matrix Business Centre,Prime Tower, Business Bay,Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/AirCharter">AirCharter</Link></li>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                            <li><Link to="/BreakBulk">BreakBulk</Link></li>
                                            <li><Link to="/RoRo">RoRo</Link></li>
                                            <li><Link to="/Warehousing">WareHousing</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Ocean Freight</h3>
                            <img src={props.simg} alt=""/>
                            <div className="Freight-s">
                                <div className="f-s">
                                    <p>Ocean freight is the most common mode of transport that importers and exporters use.</p>
                                    <p>Ocean freight shipping is highly coordinated and gives the freedom to customers to ship their cargo virtually anywhere in the world. The massive global coverage ensures convenient delivery of goods from one destination to another through ocean freight shipping.</p>
                                    <p>We at VEDS’ take pride in having a powerful network of partners globally, who provide us the most proficient solutions in a matter of few hours, ensuring your cargo moves seamlessly from origin to destination.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportOcean;

/*
<div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Ocean Logistics & Transport</h3>
                            <p>Besides our 3 decades of exposure in the conservative forwarding industry, we take pride in having niche of moving Ocean / RFS combination movements & Break-bulk projects consisting Drybulk, Ship chartering, Yacht transport, RORO and even bulk containerized shipping.</p>
                            <img src={props.simg} alt=""/>
                            <p>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>Commodities that we have executed are - Infrastructure & construction heavy vehicles, Military equipment, Oilfield cargo, Transformers & generators, Dry bulk commodities like cement, grains, clinkers etc., Pre-fabricated housing, Armored & general cars, Luxury massive Yachts, small Coast guard vessels & helicopters for repair & return.</span>
                                    <span>RORO and vehicles of any kind, be it commercial or government/military use, armored or civilian, we can handle.</span>
                                </div>
                            </div>
                        </div>
                    </div>
*/