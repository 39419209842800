import React from 'react';
//import {Link} from 'react-router-dom'
import sv1 from '../../images/service/1.jpg'
import sv2 from '../../images/service/2.jpg'
import sv3 from '../../images/service/3.jpg'
import sv4 from '../../images/service/4.jpg'
import sv5 from '../../images/service/5.jpg'
import sv6 from '../../images/service/6.jpg'
import {Link} from 'react-router-dom'
import ScrollToTop from '../scrollTop/scrolltop'


const ServiceArea2 = ( props ) => {

    return(
        <div className="wpo-service-area">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>We Provide the Best</span>
                        <h2>Services</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Link to="/AirCharter">
                        <ScrollToTop />
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv1} alt=""/>
                                </div>
                                <div className="service-content3">
                                    <h3>Air Charter</h3>
                                    <p>First Class of Cargo Charters & Private Jets</p> 
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Link to="/Ocean-freight">
                        <ScrollToTop />
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv2} alt=""/>
                                </div>
                                <div className="service-content2">
                                    <h3>Ocean Freight</h3>
                                    <p>Global Ocean Freight shipping solutions to expand your Trade</p>
                                    
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Link to="/Air-freight">
                        <ScrollToTop />
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv3} alt=""/>
                                </div>
                                <div className="service-content3">
                                    <h3>Air Freight</h3>
                                    <p>Our Air Freight shipping solutions to achieve your business goals</p>
                                    
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Link to="/BreakBulk">
                        <ScrollToTop />
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv4} alt=""/>
                                </div>
                                <div className="service-content4">
                                    <h3>Breakbulk & Projects</h3>
                                    <p>Anything Outsized / Overweight and Project Movements</p>
                                    
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Link to="/RoRo">
                        <ScrollToTop />
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv5} alt=""/>
                                </div>
                                <div className="service-content2">
                                    <h3>RoRo</h3>
                                    <p>For your all Valuable cargo on wheels</p>
                                    
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Link to="/Warehousing">
                        <ScrollToTop />
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={sv6} alt=""/>
                                </div>
                                <div className="service-content6">
                                    <h3>Warehousing</h3>
                                    <p>For all your storage requirements, complimenting your distribution needs</p>
                                    
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea2;


/*
<Link to="/servicesingle">See More...</Link>
<Link to="/servicesingle">See More...</Link>
<Link to="/servicesingle">See More...</Link>
<Link to="/servicesingle">See More...</Link>
<Link to="/servicesingle">See More...</Link>
<Link to="/servicesingle">See More...</Link>
*/