import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import TransportWarehousing from '../../components/TransportWarehousing';
//import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
import warehousing from '../../images/warehousing/warehousing.jpg'
//import ocean1 from '../../images/ocean/ocean1.jpg'
import ocean2 from '../../images/ocean/ocean2.jpg'
//import air2 from '../../images/air/2.jpg'

const Warehousing = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Warehousing'} bdsub={'Warehousing'}/>
           <TransportWarehousing simg={warehousing} simg2={ocean2}/>
           <FooterSection/>
       </div>
    )
}

export default Warehousing;