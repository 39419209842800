import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportBreakBulk = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: +971 4 3245 604</p>
                                        <p>Tel: +971 54 3611 44 1</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>nisarg@vedslogistics.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Blue Matrix Business Centre,Prime Tower, Business Bay,Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/AirCharter">AirCharter</Link></li>
                                            <li><Link to="/Ocean-freight">Ocean Freight</Link></li>
                                            <li><Link to="/Air-freight">Air Freight</Link></li>
                                            <li><Link to="/BreakBulk">BreakBulk</Link></li>
                                            <li><Link to="/RoRo">RoRo</Link></li>
                                            <li><Link to="/Warehousing">WareHousing</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Break Bulk & Projects</h3>
                            <img src={props.simg} alt=""/>
                            <div className="Freight-s">
                                <div className="f-s">
                                    <p>From yachts to planes, from tractors to helicopters, each breakbulk cargo shipment presents its own unique challenges. VEDS’ understands that cargo doesn't begin and end with containers, and that's why we're by your side, creating a custom solution that covers every step of the process. We make sure every individual piece of cargo is handled correctly and safely stowed in a flat rack or fully secured right on the vessel itself, ready to get to its final destination in perfect condition.</p>
                                    <p>As a niche global carrier, personal service and communication are our watchwords, and these are the qualities essential to moving breakbulk cargo efficiently and cost-effectively. For hundreds of small sacks, one piece of heavy equipment, and everything in between, our expertise, experience, and professional know-how are at your service, offering your cargo a first-class voyage.</p>
                                    <p>The world of breakbulk transportation is unique and challenging. VEDS’ possess wide range of experience with an ability to develop complex shipping programs. In today’s cost-cutting climate, such experiences can hold tremendous value</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default TransportBreakBulk;

/*
<div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Ocean Logistics & Transport</h3>
                            <p>Besides our 3 decades of exposure in the conservative forwarding industry, we take pride in having niche of moving Ocean / RFS combination movements & Break-bulk projects consisting Drybulk, Ship chartering, Yacht transport, RORO and even bulk containerized shipping.</p>
                            <img src={props.simg} alt=""/>
                            <p>Key factors to keep in mind when you choose VEDS to be your services provider Partner.</p>
                            <div className="Freight-s">
                                <div className="Freight-img">
                                     <img src={props.simg2} alt=""/>
                                </div>
                                <div className="f-s">
                                    <span>Commodities that we have executed are - Infrastructure & construction heavy vehicles, Military equipment, Oilfield cargo, Transformers & generators, Dry bulk commodities like cement, grains, clinkers etc., Pre-fabricated housing, Armored & general cars, Luxury massive Yachts, small Coast guard vessels & helicopters for repair & return.</span>
                                    <span>RORO and vehicles of any kind, be it commercial or government/military use, armored or civilian, we can handle.</span>
                                </div>
                            </div>
                        </div>
                    </div>
*/